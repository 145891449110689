const STORED_PATH_KEY = "frameup_last_path";
const EXPIRATION_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds

interface StoredPath {
  path: string;
  timestamp: number;
}

export function getStoredPath(): string | null {
  const stored = localStorage.getItem(STORED_PATH_KEY);
  if (!stored) return null;

  const data: StoredPath = JSON.parse(stored);
  const now = Date.now();

  if (now - data.timestamp > EXPIRATION_TIME) {
    localStorage.removeItem(STORED_PATH_KEY);
    return null;
  }

  return data.path;
}

export function setStoredPath(path: string): void {
  console.log("setStoredPath...");
  const data: StoredPath = {
    path,
    timestamp: Date.now(),
  };
  localStorage.setItem(STORED_PATH_KEY, JSON.stringify(data));
}
