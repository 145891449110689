// TODO: authenticatedFetch is no longer necessary because app bridge automatically adds the auth headers to the standard fetch function. should remove but be careful with the auto-retry stuff.

export async function authenticatedFetch(_, input, options, retry = false) {
  const retryCount = retry ? 3 : 0;
  const retryDelay = 500;
  let response;

  for (let i = 0; i <= retryCount; i++) {
    response = await fetch(input, options);
    if (response.ok) {
      break;
    }
    await sleep(retryDelay * (i + 1));
  }

  return response;
}

export async function metric(_, type: string, info?: any) {
  authenticatedFetch(_, "/api/metrics/", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      type: type,
      info: info,
    }),
  });
}

export async function publishImage(_, product, image, thinConfig) {
  // Get an image upload URL
  const responseRaw = await authenticatedFetch(
    _,
    `/api/products/generateUploadUrl`,
    {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  );
  const response = await responseRaw.json();

  if (response.result === "success") {
    // Upload image file to S3
    await fetch(response.uploadUrl, {
      method: "PUT",
      headers: {
        content_type: "image/jpeg", //hardcoded...
        acl: "private", //hardcoded...
      },
      body: image,
    });

    // POST S3 URL to Shopify
    await authenticatedFetch(
      _,
      `/api/products/${product.handle}/publishImage/`,
      {
        method: "POST",
        body: JSON.stringify({
          productId: product.id,
          imageUrl: response.resourceUrl,
          starboardConfig: thinConfig,
          // sceneId: sceneId, // TODO
          // artworkStyle: artworkStyle, // TODO
        }),
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );

    return response.resourceUrl;
  } else {
    console.log("Something went wrong...");
    throw new Error("Failed to acquire upload URL");
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
