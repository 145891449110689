import "./App.css";
import "@shopify/polaris/build/esm/styles.css";

import React from "react";

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Dashboard from "./Dashboard.tsx";
import ProductImageReviewer from "./ProductImageReviewer.tsx";
import AppEmbed from "./AppEmbed.tsx";
import ManageSubscriptionPage from "./ManageSubscriptionPage.tsx";

import enTranslations from "@shopify/polaris/locales/en.json";

import { AppProvider /*, EmptyState, Card*/ } from "@shopify/polaris";

import reportWebVitals from "./reportWebVitals";
//@ts-ignore
import { metric } from "./APIUtils.ts";

import { NavMenu } from "@shopify/app-bridge-react";

import { getStoredPath, setStoredPath } from "./helpers/storedPath.ts";
import { Link, NavLink } from "react-router-dom";

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
function LegacyLink({ children, url = "", external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
}

let webVitalReportState = {};

function App({ shop, host }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Initial redirect to stored path
  // Note: Stored path is used to return user to /virtual-gallery after leaving app to go to a permissions screen
  React.useEffect(() => {
    const storedPath = getStoredPath();
    if (storedPath && storedPath !== location.pathname) {
      navigate(storedPath);
      setStoredPath("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // See: https://shopify.dev/tutorials/get-and-store-the-shop-origin

  if (shop && host) {
    reportWebVitals(({ id, name, value }) => {
      // Only report each web vital once. Ignore duplicate callbacks possibly caused by Shopify's separate web vitals implementation.
      if (!webVitalReportState[name]) {
        webVitalReportState[name] = true;
        metric(null, `performance_${name.toLowerCase()}`, {
          value: value,
          id: id,
          name: name,
        });
      }
    });

    return (
      <AppProvider i18n={enTranslations} linkComponent={LegacyLink}>
        {(shop === "frame-up-staging.myshopify.com" ||
          shop === "free-illustration.myshopify.com" ||
          shop === "art-spot-gallery.myshopify.com" ||
          shop === "b28591-84.myshopify.com") && (
          <NavMenu>
            <NavLink to="/" rel="home">
              Home
            </NavLink>
            <NavLink to="/virtual-gallery">Virtual Gallery</NavLink>
            {true && (
              <NavLink to="/mockup-generation">Mockup Generation</NavLink>
            )}
          </NavMenu>
        )}
        <Routes>
          <Route index element={<Dashboard />} />
          <Route
            path="mockup-generation"
            element={<ProductImageReviewer shop={shop} />}
          />
          <Route path="virtual-gallery" element={<AppEmbed />} />
          <Route
            path="manage-subscription"
            element={<ManageSubscriptionPage />}
          />
          <Route
            path=":_/manage-subscription"
            element={<ManageSubscriptionPage />}
          />
        </Routes>
      </AppProvider>
    );
  } else {
    // const queryParams = new URLSearchParams(window.location.search);
    // const imageDataUrl = decodeURIComponent(queryParams.get("imageDataUrl"));
    // console.log(imageDataUrl);

    // const link = document.createElement("a");
    // link.href = imageDataUrl;
    // link.download = "mockup.png";
    // link.click();

    return (
      <AppProvider>
        No shop found...
        {/* <ProductImageReviewer shop={shop} app={app} /> */}
        {/* <Card sectioned>
          <EmptyState heading="No shop found">
            <p>
              You should launch this Shopify app via the Shopify admin if
              already installed, otherwise you can install it via:
              <br />
              <br />
              <b>https://[this-domain]/api/auth?shop=[your-shop-uri]</b>
            </p>
          </EmptyState>
        </Card> */}
      </AppProvider>
    );
  }
}

export default App;
