/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Badge,
  Button,
  Card,
  Layout,
  Page,
  SkeletonDisplayText,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { AreaHeading } from "./components/Text";

export default function Dashboard() {
  // const [themePluginEnabled, setThemePluginEnabled] = useState(false);
  // const [isSubscriber, setIsSubscriber] = useState(true);
  // const [isLegacyCustomer, setIsLegacyCustomer] = useState(false); // Anyone who installed the app before a certain date
  // const [hasExploredVirtualGallery, setHasExploredVirtualGallery] =
  //   useState(true); // User has completed the onboarding essentially

  const [dashboardData, setDashboardData] = useState<
    | {
        isSubscriber: boolean;
        isLegacyCustomer: boolean;
        hasExploredVirtualGallery: boolean;
        themePluginEnabled: boolean;
      }
    | undefined
  >();

  useEffect(() => {
    const f = async () => {
      const response1 = await fetch("/api/dashboard", {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const data = await response1.json();

      const response2 = await fetch("/api/appEmbed/theme-block-status");
      const { isEnabled } = await response2.json();

      setDashboardData({ ...data, themePluginEnabled: isEnabled });
    };
    f();
  }, []);

  const [fetchingTrialUrl, setFetchingTrialUrl] = useState(false);

  const startSubscriptionTrial = useCallback(async () => {
    setFetchingTrialUrl(true);

    const responseRaw = await fetch(`/api/settings/selectSubscription`, {
      method: "POST",
      body: JSON.stringify({ tier: "Automate 500" }),
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    const response = await responseRaw.json();
    console.log("selectTier response: " + JSON.stringify(response));

    if (response.result === "success") {
      // getStatus();
      console.log(`Should be redirecting to: ${response.confirmationUrl}`);
      // redirect.dispatch(Redirect.Action.REMOTE, response.confirmationUrl);
      window.open(response.confirmationUrl, "_top");
    } else {
      setFetchingTrialUrl(false);
    }
  }, []);

  return (
    <Page
      title="Welcome to Frame Up 👋"
      secondaryActions={(() => {
        let secondaryActions = [];
        secondaryActions.push({
          content: "Support",
          url: "mailto:support@frameup.app",
        });
        return secondaryActions;
      })()}
      actionGroups={
        false
          ? []
          : [
              {
                title: "More actions",
                onClick: (openActions) => {
                  openActions();
                },
                actions: [
                  {
                    content: "Manage Subscription",
                    url: "./manage-subscription",
                  },
                ],
              },
            ]
      }
    >
      <div className="w-4/5 m-auto mt-4">
        <Layout>
          <Layout.Section>
            <Card>
              <div className="justify-between align-middle flex-col space-y-4 sm:flex-row sm:space-x-2 sm:flex sm:space-y-0">
                <div>
                  <div className="flex space-x-2 mb-1">
                    <AreaHeading>Virtual Gallery</AreaHeading>
                    <div className="content-center">
                      {dashboardData?.isSubscriber && (
                        <>
                          {dashboardData?.hasExploredVirtualGallery ? (
                            <>
                              {dashboardData?.themePluginEnabled ? (
                                <Badge
                                  tone="success"
                                  progress="complete"
                                  size="small"
                                >
                                  Theme Plugin Enabled
                                </Badge>
                              ) : (
                                <>
                                  <Badge progress="incomplete" size="small">
                                    Theme Plugin Disabled
                                  </Badge>
                                </>
                              )}
                            </>
                          ) : (
                            <Badge tone="info" size="small">
                              New
                            </Badge>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <p>Showcase your art in stunning 3D with room views</p>
                </div>
                <div className="content-center">
                  {!dashboardData ? (
                    <Button size="large" variant="secondary" disabled loading>
                      Open Virtual Gallery
                    </Button>
                  ) : dashboardData?.isSubscriber ? (
                    <Button
                      size="large"
                      variant={
                        dashboardData?.isLegacyCustomer
                          ? "secondary"
                          : "primary"
                      }
                      tone={
                        dashboardData?.hasExploredVirtualGallery &&
                        !dashboardData?.isLegacyCustomer
                          ? null
                          : "success"
                      }
                      url="/virtual-gallery"
                    >
                      {dashboardData?.hasExploredVirtualGallery
                        ? "Open"
                        : "Try"}
                      &nbsp;Virtual&nbsp;Gallery
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant={"primary"}
                      tone="success"
                      loading={fetchingTrialUrl}
                      onClick={() => {
                        startSubscriptionTrial();
                      }}
                    >
                      Start 10-Day Free Trial
                    </Button>
                  )}
                </div>
              </div>
            </Card>
            <br />
            {dashboardData?.isLegacyCustomer && (
              <Card>
                <div className="justify-between align-middle flex-col space-y-4 sm:flex-row sm:space-x-2 sm:flex sm:space-y-0">
                  <div>
                    <div className="flex space-x-2 mb-1">
                      <AreaHeading>Mockup Generation</AreaHeading>
                    </div>
                    <p>Create mockup images for your art</p>
                  </div>
                  <div className="content-center">
                    <Button
                      size="large"
                      variant={
                        dashboardData?.isLegacyCustomer
                          ? "primary"
                          : "secondary"
                      }
                      url="/mockup-generation"
                    >
                      Open&nbsp;Mockup&nbsp;Generation
                    </Button>
                  </div>
                </div>
              </Card>
            )}
            {/* <br />
          <Card>
            <div>
              <AreaHeading className="mb-3">Getting Started</AreaHeading>
              <p className="mb-5">
                See how Frame Up can elevate your art with our 10 day free
                trial.
              </p>
              <div className="mb-2">
                <Button size="large" variant="primary" onClick={() => {}}>
                  Start Free Trial
                </Button>
              </div>
              <div className="text-gray-500 text-xs">
                Try Frame Up for 10 days for free with subscription options
                starting at $9.99/month
              </div>
            </div>
          </Card> */}
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
}
