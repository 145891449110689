import { Icon } from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";
import React, { ReactNode } from "react";

interface OnboardingCellProps {
  currentTask: boolean;
  complete: boolean;
  children?: ReactNode;
}

function OnboardingCell(props: OnboardingCellProps) {
  return (
    <div
      className={`flex space-x-3 items-center min-h-6 ${
        props.currentTask && !props.complete ? "opacity-100" : "opacity-50"
      }`}
    >
      {props.complete ? (
        <div className="inline-block flex-shrink-0">
          <Icon source={CheckIcon} />
        </div>
      ) : (
        <div className="inline-block flex-shrink-0 rounded-full w-4 h-4 mx-0.5 border-gray-400 border-dashed border-[1.5px]"></div>
      )}
      <div>{props.children}</div>
    </div>
  );
}

export { OnboardingCell };

/*
<div className="flex space-x-3 items-center">
  <div className="inline-block flex-shrink-0 rounded-full w-4 h-4 mx-0.5 border-gray-400 border-dashed border-[1.5px]"></div>
  <div className="">
    Customize plugin in{" "}
    <button
      className="underline"
      onClick={() => {
        setPulseElement("settings");
        document.querySelector("#settings").scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      Settings
    </button>
  </div>
</div>
*/
