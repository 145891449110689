import { Combobox, Icon, Listbox, Spinner } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";
import { authenticatedFetch } from "../APIUtils";

interface ProductSearchBoxProps {
  app: any;
  disabled: boolean;
  selectedProduct: (product: any) => void;
  shouldFocusInput: boolean;
}

export default function ProductSearchBox(props: ProductSearchBoxProps) {
  const [abortController, setAbortController] = useState(new AbortController());
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchError, setFetchError] = useState<any>(null); // TODO: handle fetchError...
  const [products, setProducts] = useState<any>(null);

  const [focusInput, setFocusInput] = useState(false);

  useEffect(() => {
    if (props.shouldFocusInput) {
      setFocusInput(true);
    }
  }, [props.shouldFocusInput]);

  const fetchProducts = useCallback(
    (term) => {
      const f = async (term) => {
        if (isLoading) {
          // console.log("Overruling with: " + term);
          abortController.abort();
        }
        const ac = new AbortController();
        setAbortController(ac);

        setIsLoading(true);
        setFetchError(null);

        try {
          let path = `/api/appEmbed/products?search_term=${term}`;
          const responseRaw = await authenticatedFetch(
            props.app,
            path,
            {
              headers: {
                "Content-Type": "application/json",
              },
              signal: ac.signal,
            },
            true
          );
          // console.log("Processing: " + term);
          const response = await responseRaw.json();
          console.log("Processed: " + term);
          console.log(response);

          setProducts(() => {
            return response.products;
          });

          setIsLoading(false);
        } catch (err) {
          if (err.name === "AbortError") {
            // console.log("Aborted: " + term);
          } else {
            setFetchError(err);
            setIsLoading(false);
          }
        }
      };
      f(term);
    },
    [abortController, isLoading, props.app]
  );

  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState("");

  // const escapeSpecialRegExCharacters = useCallback(
  //   (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
  //   []
  // );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      fetchProducts(value);

      // if (value === "") {
      //   setOptions(deselectedOptions);
      //   return;
      // }

      // const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");
      // const resultOptions = deselectedOptions.filter((option) =>
      //   option.label.match(filterRegex)
      // );
      // setOptions(resultOptions);
    },
    [fetchProducts]
    // [escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      const matchedOption = products.find((product) => {
        return product.id.match(selected);
      });

      props.selectedProduct(matchedOption);

      setSelectedOption(selected);
      setInputValue((matchedOption && matchedOption.title) || "");

      setFocusInput(true);
      setTimeout(() => {
        setFocusInput(false);
      }, 1);
    },
    [products, props]
  );

  const optionsMarkup =
    products?.length > 0
      ? products.map((product) => {
          const value = product.id;
          const label = product.title;

          return (
            <div className="cursor-pointer">
              <Listbox.Option
                key={`${value}`}
                value={value}
                selected={selectedOption === value}
                accessibilityLabel={label}
              >
                <div
                  className={`px-2 py-1 flex flex-row gap-2 items-center w-full hover:bg-slate-50 ${
                    selectedOption === value ? " bg-slate-100" : ""
                  }`}
                >
                  <img
                    src={product.images[0]?.transformedSrc}
                    className="w-10 h-10 rounded-md object-cover bg-gray-300"
                    alt=""
                  />
                  <div className="inline-block h-fit">{label}</div>
                </div>
              </Listbox.Option>
            </div>
          );
        })
      : null;

  return (
    <div>
      <Combobox
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label="Product search"
            labelHidden
            value={inputValue}
            placeholder="Type a product name..."
            autoComplete="off"
            disabled={props.disabled}
            focused={focusInput}
            onFocus={() => {
              setInputValue("");
            }}
          />
        }
        children={
          inputValue?.length > 0 ? (
            <div className="-my-1">
              {isLoading ? (
                <div className="p-3 flex space-x-2 font-semibold">
                  <div>
                    <Spinner size="small" />
                  </div>
                  <div> Loading...</div>
                </div>
              ) : products?.length > 0 ? (
                <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
              ) : (
                <div className="p-3 font-semibold">
                  No matching products found
                </div>
              )}
            </div>
          ) : null
        }
      />
    </div>
  );
}
