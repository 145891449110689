import { Popover, ActionList, Button } from "@shopify/polaris";
import { MenuHorizontalIcon } from "@shopify/polaris-icons";
import { useState, useCallback } from "react";

interface DismissDropdownButtonProps {
  dismissAction: () => void;
}

export default function DismissDropdownButton(
  props: DismissDropdownButtonProps
) {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  return (
    <Popover
      active={active}
      activator={
        <Button
          onClick={toggleActive}
          // disclosure
          icon={MenuHorizontalIcon}
          variant="monochromePlain"
        ></Button>
      }
      autofocusTarget="first-node"
      onClose={toggleActive}
    >
      <ActionList
        actionRole="menuitem"
        items={[
          {
            content: "Dismiss",
            onAction: () => {
              props.dismissAction();
            },
          },
        ]}
      />
    </Popover>
  );
}
