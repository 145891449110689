/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Tag,
  LegacyStack as Stack,
  Text,
  Banner,
  Button,
} from "@shopify/polaris";

interface ProductTypeSelectionProps {
  selectedOptions: string[];
  setSelectedOptions: (selected: string[]) => void;
  openManageSubscription: () => void;
}

export function ProductTypeSelection({
  selectedOptions,
  setSelectedOptions,
  openManageSubscription,
}: ProductTypeSelectionProps) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [productCount, setProductCount] = useState<number | null>(null);
  const [countLoading, setCountLoading] = useState(false);

  const updateText = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map((selectedItem) => {
      return selectedItem;
    });
    setSelectedOptions(selectedValue);
  }, []);

  const removeTag = useCallback(
    (tag: string) => () => {
      const newSelectedOptions = selectedOptions.filter(
        (option) => option !== tag
      );
      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions]
  );

  const tagsMarkup = selectedOptions.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await fetch("/api/appEmbed/product-types");
        const data = await response.json();
        setOptions(data.productTypes);
      } catch (error) {
        console.error("Error fetching product types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductTypes();
  }, []);

  useEffect(() => {
    const fetchProductCount = async () => {
      setCountLoading(true);
      try {
        const params = new URLSearchParams();
        selectedOptions.forEach((type) =>
          params.append("product_types[]", type)
        );
        const response = await fetch(
          `/api/appEmbed/product-count?${params.toString()}`
        );
        const data = await response.json();
        setProductCount(data.count);
      } catch (error) {
        console.error("Error fetching product count:", error);
        setProductCount(null);
      } finally {
        setCountLoading(false);
      }
    };

    fetchProductCount();
  }, [selectedOptions]);

  const deselectedOptions = useMemo(() => {
    return options
      .filter((option) =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((option) => ({
        label: option,
        value: option,
      }));
  }, [options, selectedOptions, inputValue]);

  const textField = (
    <Autocomplete.TextField
      label=""
      onChange={updateText}
      value={inputValue}
      placeholder="Search product types"
      autoComplete="off"
      verticalContent={<Stack spacing="tight">{tagsMarkup}</Stack>}
      onBlur={() => {
        setInputValue("");
      }}
    />
  );

  return (
    <Stack vertical spacing="tight">
      <Autocomplete
        allowMultiple
        options={deselectedOptions}
        selected={selectedOptions}
        onSelect={updateSelection}
        loading={loading}
        textField={textField}
      />
      {(productCount !== null || countLoading) && (
        <div style={{ marginTop: "4px" }}>
          {countLoading ? (
            <Stack spacing="tight" alignment="center">
              <Text as="span" variant="bodyMd" tone="subdued">
                Calculating product count...
              </Text>
            </Stack>
          ) : productCount > 500 ? (
            <Banner tone="warning" title="Product limit exceeded">
              {selectedOptions.length > 0 ? (
                <>
                  <div className="mb-2">
                    Your selected product types add up to <b>{productCount}</b>{" "}
                    products but your tier only supports up to <b>500</b>{" "}
                    products.
                  </div>
                  <div className="mb-2">
                    Reduce your product count by changing product types or
                    upgrade your subscription.
                  </div>
                  <div>
                    <Button
                      url="./manage-subscription"
                      onClick={openManageSubscription}
                    >
                      Manage Subscription
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-2">
                    Your store has <b>{productCount}</b> products but your Frame
                    Up tier supports up to <b>500</b> products.
                  </div>
                  <div className="mb-2">
                    Reduce your product count by selecting a product type or
                    upgrade your subscription.
                  </div>
                  <div>
                    <Button url="./manage-subscription">
                      Manage Subscription
                    </Button>
                  </div>
                </>
              )}
            </Banner>
          ) : selectedOptions.length === 0 ? (
            <Text as="span" variant="bodyMd">
              No product types selected. Plugin will be enabled for all{" "}
              <b>{productCount}</b> product{productCount === 1 ? "" : "s"}.
            </Text>
          ) : (
            <Text as="span" variant="bodyMd" tone="subdued">
              Plugin will be enabled for <b>{productCount}</b> product
              {productCount === 1 ? "" : "s"}
            </Text>
          )}
        </div>
      )}
    </Stack>
  );
}
